import React, { useState } from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';
import satan_blocker from '../assets/images/satan-blocker.png';

const Love = () => {
  const [counter, setCounter] = useState(100);
  //increase counter
  const increase = () => {
    setCounter(count => count + 1);
  };

  //decrease counter
  const decrease = () => {
    setCounter(count => count - 1);
  };

  //reset counter
  const reset = () => {
    setCounter(100);
  };
  return (
    <Layout>
      <Sidebar showTabs="steps" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="top"
      >
        <ul>
          <li>
            <h4>Hypocrisy</h4>
            <h6> النفاق</h6>
          </li>

          <ol></ol>
          <p />
          <h3>References</h3>
          <ul>
            <li>
              <a href="https://medium.com/jumah-nugget/beware-of-hypocrisy-and-its-manifestation-90c0ee17d9c6">
                Beware of Hypocrisy and it’s Manifestation
              </a>
            </li>
            <li>
              <a href="https://www.islamweb.net/ar/fatwa/1854/%D8%A7%D9%84%D9%86%D9%81%D8%A7%D9%82-%D9%86%D9%88%D8%B9%D8%A7%D9%86-%D8%A3%D9%83%D8%A8%D8%B1-%D9%88%D8%A3%D8%B5%D8%BA%D8%B1">
                النفاق نوعان أكبر وأصغر
              </a>
            </li>
            <li>
              <a href="https://www.islamweb.net/en/fatwa/344005/what-is-considered-hypocrisy-and-what-is-not">
                What is considered hypocrisy and what is not
              </a>
            </li>
            <li>
              <a href="https://www.al-islam.org/hypocrites-commentary-surah-al-munafiqun-jafar-subhani/signs-hypocrisy">
                Signs of Hypocrisy
              </a>
            </li>
            <li>
              <a href="https://www.islamweb.net/ar/fatwa/49398/%D8%A7%D9%84%D8%B7%D8%B1%D9%8A%D9%82-%D8%A5%D9%84%D9%89-%D9%85%D8%B9%D8%B1%D9%81%D8%A9-%D8%A7%D9%84%D8%A5%D9%86%D8%B3%D8%A7%D9%86-%D8%A8%D9%83%D9%88%D9%86%D9%87-%D9%85%D9%86%D8%A7%D9%81%D9%82%D8%A7-%D8%A3%D9%88-%D8%BA%D9%8A%D8%B1-%D9%85%D9%86%D8%A7%D9%81%D9%82">
                الطريق إلى معرفة الإنسان بكونه منافقا أو غير منافق
              </a>
            </li>
            <li>
              <a href="https://sunnah.com/search?q=النفاق">
                https://sunnah.com/search?q=النفاق{' '}
              </a>
            </li>
            <li>
              <a href="https://sunnah.com/search?q=منافق">
                https://sunnah.com/search?q=منافق{' '}
              </a>
            </li>
            <li>
              <a href="https://medium.com/jumah-nugget/beware-of-hypocrisy-and-its-manifestation-90c0ee17d9c6">
                Beware of Hypocrisy and it’s Manifestation
              </a>
            </li>
            <li>
              <a href="https://islamqa.info/ar/answers/225137/%D8%A7%D8%AF%D8%B9%D9%8A%D8%A9-%D8%AB%D8%A7%D8%A8%D8%AA%D8%A9-%D9%84%D9%84%D9%88%D9%82%D8%A7%D9%8A%D8%A9-%D9%85%D9%86-%D8%A7%D9%84%D8%B4%D8%B1%D9%83-%D9%88%D8%A7%D9%84%D9%83%D9%81%D8%B1-%D9%88%D8%A7%D9%84%D9%86%D9%81%D8%A7%D9%82">
                أدعية ثابتة للوقاية من الشرك والكفر والنفاق .
              </a>
            </li>
          </ul>
        </ul>
      </section>
      <section></section>
    </Layout>
  );
};

export default Love;
